// Fonts
$fonts: "Nunito", "Varela Round" ,sans-serif;

// :: 2.0 Template Colors

// Primary
$primary: #212529;

// White
$white: #ffffff;

// Secondary
$secondary: #0c0c0c;

// Dark
$dark: #0c0c0c;

// Heading
$heading: #343A40;

// Text
$text: #495057;

// Text Gray
$text-gray: #6C757D;

// Border
$border: #343A40;

// Gray
$gray: #E9ECEF;

// Success
$success: #0c0c0c;

// Warning
$warning: #DEE2E6;

// Info
$info: #212529;

// Danger
$danger: #dc3545;


//Australia Flag Color Codes

$au-red: #E4002B;

$au-blue: #012169;

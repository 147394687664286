/* Footer CSS */

.footer-area {
    .footer-widget-area {
        ul {
            li {
                a {
                    display: flex;
                    align-items: center;
                    color: $text;
                    margin-bottom: 0.375rem;
                    font-size: 14px;
                    font-weight: 700;

                    i {
                        color: $heading;
                        font-size: 12px;
                        margin-right: 0.5rem;
                    }

                    &:hover,
                    &:focus {
                        color: $primary;
                    }
                }

                &:last-child {
                    a {
                        margin-bottom: 0;
                    }
                }
            }
        }

        .form-control {
            height: 45px;
            padding: 8px 20px;
        }

        .btn {
            padding: 1.75rem 1.75rem;
        }
    }

    .footer-social-icon>a {
        font-size: 1rem;
        color: $text;
        margin-right: 1.25rem;

        &:last-child {
            margin-right: 0;
        }

        &:hover,
        &:focus {
            color: $primary;
        }
    }

    .copywrite-wrapper {
        background-color: $text-gray;
        padding: 1.25rem 1.5rem;

        @media #{$breakpoint-lg} {
            padding: .75rem 1.75rem;
        }
    }

    .copywrite-text {
        p {
            font-weight: 700;
            font-size: 14px;
            color: $heading;
        }

        a {
            color: $primary;
        }
    }

    .footer-nav {
        li {
            line-height: 1.5;
            
            a {
                color: $heading;
                margin: 0 .5rem;
                font-size: 14px;
                font-weight: 700;

                @media #{$breakpoint-lg} {
                    margin: 0 .75rem;
                }

                &:hover,
                &:focus {
                    color: $primary;
                }
            }

            &:first-child {
                a {
                    margin-left: 0;
                }
            }

            &:last-child {
                a {
                    margin-right: 0;
                }
            }
        }
    }

    .language-dropdown {
        .dropdown-toggle {
            padding: 0;
            font-size: 14px;
            box-shadow: none;

            &:focus {
                box-shadow: none;
            }
        }

        .dropdown-menu {
            min-width: 6rem;
            width: 7.5rem;
            border: 1px solid $border;
            border-radius: 0.25rem;
            box-shadow: 0 0.25rem 0.25rem 0 rgba(15, 30, 150, 0.2);

            .dropdown-item {
                color: $text;
                font-size: 14px;
                font-weight: 500;

                &:hover,
                &:focus {
                    color: $primary;
                    background-color: transparent;
                }
            }
        }
    }

    &.footer-2 {
        background-color: $heading;

        .footer-widget-area {
            h5 {
                color: $white;
            }

            ul li a i {
                color: $text-gray;
            }
        }

    }
}